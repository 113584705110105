import React, { useState } from "react"
import { css, keyframes } from "@emotion/core"

const hi5 = keyframes`
  from {
    opacity: 0;
    transform: scale(0, 0) translateY(0%);
  }

  24% {
    opacity: 0.6;
    transform: scale(1, 1) translateY(-70%);
  }

  26% {
    opacity: 1;
    transform: scale(1, 1) translateY(-80%);
  }

  90% {
    opacity: 1;
    transform: scale(1, 1) translateY(-120%);
  }

  100% {
    opacity: 0;
    transform: scale(1, 1) translateY(-200%);
  }
`

const wave = keyframes`
  from, 75%, to {
    transform: rotate(0deg);
  }

  80%, 95% {
    transform: rotate(-4deg);
  }

  85%, 85.75% {
    transform: rotate(18deg);
  }

  88.25% {
    transform: rotate(-12deg);
  }

  92.5% {
    transform: rotate(14deg);
  }

  97.5% {
    transform: rotate(10deg);
  }
`

export default () => {
  const [fives, setFives] = useState({})
  return (
    <button
      tabIndex="-1"
      css={[
        css`
          display: inline-block;
          transform-origin: 70% 70%;
          cursor: grab;
          position: relative;
          transition: transform 0.1s ease;
          &:not(:focus) {
            animation: ${wave} 4s linear infinite;
          }
          &:active {
            transform: scale(1.2);
          }
        `,
      ]}
      onClick={() => setFives({ ...fives, [Date.now()]: true })}
    >
      <span
        role="img"
        aria-label="Wave"
        css={css`
          display: inline-flex;
          align-items: center;
          justify-content: center;
        `}
      >
        👋
        {Object.keys(fives).map(id => (
          <span
            key={id}
            css={css`
              position: absolute;
              padding: 4px 14px;
              background: rgba(0, 0, 0, 0.6);
              border-radius: 4px;
              color: #fff;
              opacity: 0;
              transition: opacity 0.2s ease;
              animation: ${hi5} 2s linear forwards;
              transform-origin: center center;
            `}
            onAnimationEnd={() => {
              setFives(
                Object.keys(fives)
                  .filter(i => i !== id)
                  .reduce((acc, key) => ({ ...acc, [key]: true }), {})
              )
            }}
          >
            Hi5!
          </span>
        ))}
      </span>
    </button>
  )
}
