import React from "react"
import styled from "@emotion/styled"
import { Context as FocusContext } from "./FocusDisplayHandler"
import mq from "../lib/mq"

const Button = styled.button`
  background-color: #027373;
  color: #f2b705;
  font-weight: 600;
  display: inline-block;
  width: 100%;
  font-family: inherit;
  margin-top: 30px;
  padding: 25px 32px;
  appearance: none;
  cursor: pointer;
  position: relative;
  transition: color 0.16s ease-out, transform 0.2s ease;
  transform: scale(1);
  overflow: hidden;
  border-radius: 4px;

  ${mq({
    fontSize: [18, 20, 24],
  })}

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: #f2b705;
    transition: transform 0.4s ease;
    transform: scaleX(0) skewX(-45deg);
    transform-origin: 0 0;
  }

  &:hover {
    color: #034453;
    transform: scale(1.02);
    &:before {
      transform: none;
    }
  }

  &[data-focus-method="key"]:focus {
    outline: 3px solid #f2b705;
  }
`
export default props => (
  <FocusContext.Consumer>
    {({ focusMethod }) => <Button data-focus-method={focusMethod} {...props} />}
  </FocusContext.Consumer>
)
