// import React, { useEffect, useState } from "react"
import React from 'react'
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Heading from "../components/Heading"
import Intro from "../components/Intro"
import SubHeading from "../components/SubHeading"
import Button from "../components/Button"
import Grid, { GridItem } from "../components/Grid"
import Body from "../components/Body"
import Screen from "../components/Screen"
import Work from "../components/Work"
import { css } from "@emotion/core"
import mq from "../lib/mq"
import FocusDisplayHandler from "../components/FocusDisplayHandler"
import "../styles/app.scss"
import ExternalLink from "../components/ExternalLink"
import TextLink from "../components/TextLink"
import Wave from "../components/Wave"

const availability = "February 2022"

const CAPABILITIES = [
  {
    title: "HTML & SCSS",
    body: "Accessible markup, responsive stylesheets",
    emoji: "</>",
  },
  { title: "JavaScript", body: "React, Vanilla JS, Node.js", emoji: "{ }" },
  {
    title: "CMS / Platforms",
    body: "WordPress, Shopify, Contentful, Craft",
    emoji: "📝",
  },
  {
    title: "iOS & Android",
    body: "Swift, React Native, Rubymotion",
    emoji: "📱",
  },
  { title: "Web Services", body: "Node, PHP, Ruby", emoji: "☁️" },
  {
    title: "Data storage",
    body: "Neo4j, Elasticsearch, SQL, NoSQL",
    emoji: "🥞",
  },
]

const SELECTED_WORK = [
  {
    key: "precision-run",
    title: "Precision Run",
    href: "https://apps.apple.com/us/app/precision-run/id1465355921",
    imageSrc: "precisionrun.jpg",
    tech: "UI Development ⟶ React Native / iOS & Android",
    agency: {
      href: "https://area17.com/",
      title: "AREA 17",
    },
  },
  {
    key: "max-v-koenig",
    title: "Max V. Koenig",
    href: "https://maxvkoenig.com/",
    imageSrc: "max.jpg",
    tech: "Shopify app & UI development ⟶ Node / Liquid",
    agency: {
      href: "https://strobedigital.com/",
      title: "Strobe",
    },
  },
  {
    key: "pubg",
    title: "PUBG",
    href: "https://pubg.com/",
    imageSrc: "pubg.jpg",
    tech: "Wordpress CMS & UI development ⟶ PHP / HTML / SCSS / JS",
    agency: {
      href: "https://madglory.com/",
      title: "MadGlory",
    },
  },
  {
    key: "shadow-of-war",
    title: "Shadow of War",
    href: "https://www.shadowofwar.com/",
    imageSrc: "shadowofwar.jpg",
    tech: "UI Development ⟶ Metalsmith / SCSS / Canvas",
    agency: {
      href: "https://madglory.com/",
      title: "MadGlory",
    },
  },
  {
    key: "thehill",
    title: "The Hill",
    href: "https://thehill.com/hill-apps",
    imageSrc: "thehill.jpg",
    tech: "UI Development ⟶ Swift / iOS",
    agency: {
      href: "https://area17.com/",
      title: "AREA 17",
    },
  },
  {
    key: "breath",
    title: "Breath",
    href: "https://breath.chrishale.co.uk/",
    imageSrc: "breath.jpg",
    tech: "UI Development ⟶ React / SCSS",
    agency: {
      title: "Internal",
    },
  },
]

const IndexPage = ({ data }) => {
  // const [availability, setAvailability] = useState("April 2021")
  // useEffect(() => {
  //   fetch(
  //     "https://my.cushionapp.com/api/v1/users/bf727925-fd1c-43df-b7a5-d7da467801f1/availability"
  //   )
  //     .then(r => r.json())
  //     .then(({ availability: { available_on } }) => {
  //       const date = new Date(available_on)
  //       if (date.getDate() > 15) {
  //         date.setMonth(date.getMonth() + 1)
  //       }
  //       const month = date.toLocaleString("default", { month: "long" })
  //       const year = date.getFullYear()
  //       setAvailability(`${month} ${year}`)
  //     })
  // })
  return (
    <FocusDisplayHandler>
      <Layout>
        <SEO title="Technical Direction and Engineering" />

        <Screen id="intro">
          <div
            css={css`
              border-radius: 50%;
              overflow: hidden;
              position: relative;
              display: inline-block;
              ${mq({
                width: [100, 200, 250],
                height: [100, 200, 250],
              })}
            `}
          >
            <Img
              alt="Chris Hale's Avatar"
              fixed={[
                data.small.childImageSharp.fixed,
                {
                  ...data.medium.childImageSharp.fixed,
                  media: "(min-width: 768px) and (max-width: 1199px)",
                },
                {
                  ...data.large.childImageSharp.fixed,
                  media: "(min-width: 1200px)",
                },
              ]}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>
          <Heading
            css={css`
              padding-top: 16px;
            `}
          >
            Chris Hale
          </Heading>

          <Intro
            css={css`
              display: inline-block;
              ${mq({
                maxWidth: [410, 680, 1080],
              })};
            `}
          >
            Technical Direction and Engineering{" "}
            <small>for Agencies, Creatives, and Start-ups</small>
          </Intro>

          <Body
            css={css`
              ${mq({
                paddingTop: [24, 48, 80],
              })}
            `}
          >
            <span
              css={css`
                display: inline-block;
                ${mq({
                  maxWidth: [455, 610, 710],
                })};
              `}
            >
              <Wave /> I’m looking for roles within agencies and start ups to
              help launch websites, online stores and digital products.
            </span>
          </Body>

          <div
            css={css`
              ${mq({
                paddingTop: [48, 80, 120],
              })}
              display: inline-block;
            `}
          >
            <Body color="#fff">
              Available {availability ? `from ${availability}` : "soon"}
            </Body>
            <Button
              onClick={e => {
                e.preventDefault()
                document
                  .getElementById("contact")
                  .scrollIntoView({ behavior: "smooth" })
              }}
            >
              <span>Get in touch</span>
            </Button>
          </div>
        </Screen>

        <Screen id="skills">
          <Heading>Skills</Heading>
          <div
            css={css`
              ${mq({
                paddingTop: [24, 48, 80],
              })}
            `}
          >
            <Body>
              I’ll regularly adapt my 12 years of experience to various web and
              mobile technology stacks. Typically, projects I undertake are a
              mix of:
            </Body>
          </div>

          <Grid>
            {CAPABILITIES.map(({ title, body, emoji = "👉" }, i) => (
              <GridItem key={i}>
                <SubHeading
                  css={css`
                  &:before {
                    content: "${emoji}";
                    color: #f2b705;
                    display: inline-block;
                    padding-right: 18px;
                  }
                `}
                >
                  {title}
                </SubHeading>
                <Body>{body}</Body>
              </GridItem>
            ))}
          </Grid>
        </Screen>

        <Screen id="work">
          <Heading>Selected Work</Heading>
          <div
            css={css`
              ${mq({
                paddingTop: [24, 48, 80],
              })}
            `}
          >
            <Body>
              I’ve had the pleasure to work with world renowned digital agencies
              and tech companies on some exciting projects. Below are a few
              examples I can share:
            </Body>
          </div>

          <Grid
            css={css`
              a:focus[data-focus-method="key"] {
                outline: 3px solid #f2b705;
                outline-offset: 8px;
              }
            `}
          >
            {SELECTED_WORK.map(({ key, ...work }, index) => (
              <GridItem key={key}>
                <Work {...work} />
              </GridItem>
            ))}
          </Grid>
        </Screen>

        <Screen id="contact">
          <Heading>Get in touch</Heading>
          <div
            css={css`
              display: inline-block;
              ${mq({
                maxWidth: [410, 680, 1200],
                paddingTop: [24, 48, 80],
              })}
            `}
          >
            <Body>
              I'm based in Manchester, UK with a lot of experience working
              remotely for companies worldwide. Please reach out if you have a
              project in mind{" "}
              <span role="img" aria-label="Thumbs Up">
                👍
              </span>
            </Body>
          </div>
          <Intro
            css={css`
              ${mq({
                paddingTop: [48, 64, 80],
              })}
            `}
          >
            <TextLink href="mailto:hello@chrishale.co.uk" title="Email me">
              hello@chrishale.co.uk
            </TextLink>
          </Intro>
          <Grid justify="center" cols={3}>
            <GridItem>
              <SubHeading>Github</SubHeading>
              <Body>
                <ExternalLink
                  href="https://github.com/chrishale"
                  title="View my Github profile"
                >
                  github.com/chrishale
                </ExternalLink>
              </Body>
            </GridItem>

            <GridItem>
              <SubHeading>Instagram</SubHeading>
              <Body>
                <ExternalLink
                  href="https://www.instagram.com/chris._.hale/"
                  title="View my Instagram profile"
                >
                  @chris._.hale
                </ExternalLink>
              </Body>
            </GridItem>
          </Grid>
          <Body
            css={css`
              padding-top: 160px;

              small {
                display: block;
                font-size: 14px;
                line-height: 1.8;
              }
            `}
          >
            <small>Built by Chris Ltd is registered in England and Wales</small>
            <small>Company Number 12657469</small>
          </Body>
        </Screen>
      </Layout>
    </FocusDisplayHandler>
  )
}

export default IndexPage

export const query = graphql`
  query {
    small: file(relativePath: { eq: "chris.jpg" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    medium: file(relativePath: { eq: "chris.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    large: file(relativePath: { eq: "chris.jpg" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
