import styled from "@emotion/styled"
import mq from "../lib/mq"

export default styled.h3`
  ${mq({
    fontSize: [18, 24, 32],
  })}
  line-height: 1.5;
  max-width: 910px;
  color: #fff;
  font-weight: 900;
  padding-top: 32px;
`
