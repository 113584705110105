import React from "react"
import Image from "./Image"
import SubHeading from "./SubHeading"
import { css } from "@emotion/core"
import Body from "./Body"
import { Context as FocusContext } from "./FocusDisplayHandler"
import Tilt from "react-parallax-tilt"
import externalIcon from "../icons/external.svg"
import ExternalLink from "./ExternalLink"
// import ScrollTrap from "./ScrollTrap"
// import Screen from "./Screen"

// const HOVER_TRIGGER_DELAY = 2000
// const HOVER_BORDER_SIZE = 5

// const { duration, delay } = ((ratio, time) => {
//   const [x, y] = ratio.split(":").map(i => parseInt(i))
//   const sides = [x, y, x, y]
//   const total = sides.reduce((a, b) => a + b)
//   const calcDuration = (index = 0) => {
//     return (time / total) * sides[index]
//   }
//   return {
//     duration: index => `${calcDuration(index)}ms`,
//     delay: index =>
//       `${sides
//         .filter((_, i) => i < index)
//         .reduce((a, b, index) => a + calcDuration(index), 0)}ms`,
//   }
// })("5:3", HOVER_TRIGGER_DELAY)

// const BlurSVG = () => (
//   <svg
//     css={css`
//       ellipse {
//         fill: #023a47;
//         animation-duration: 40s;
//         animation: none;
//         cx: 250;
//         cy: 250;
//         rx: 120;
//         ry: 140;
//       }

//       ellipse:nth-of-type(1) {
//         cx: 180;
//         cy: 280;
//       }
//       ellipse:nth-of-type(2) {
//         cx: 150;
//         cy: 270;
//       }
//       ellipse:nth-of-type(3) {
//         cx: 270;
//         cx: 200;
//       }
//       ellipse:nth-of-type(4) {
//         cx: 280;
//         cy: 290;
//       }
//     `}
//     filter="url(#goo)"
//     viewBox="0 0 500 500"
//   >
//     <defs>
//       <filter id="goo">
//         <feGaussianBlur in="SourceGraphic" stdDeviation={12} result="blur" />
//         <feColorMatrix
//           in="blur"
//           mode="matrix"
//           values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
//           result="goo"
//         />
//         <feComposite in="SourceGraphic" in2="goo" operator="atop" />
//       </filter>
//     </defs>
//     <g>
//       {[
//         { from: "360 250 250", to: "0 250 250" },
//         { from: "0 250 250", to: "360 250 250" },
//         {
//           from: "0 250 250",
//           to: "360 250 250",
//         },
//         {
//           from: "360 250 250",
//           to: "0 250 290",
//         },
//         {
//           from: "360 300 250",
//           to: "0 250 200",
//         },
//       ].map(({ from, to }, key) => (
//         <ellipse key={key}>
//           <animateTransform
//             attributeType="xml"
//             attributeName="transform"
//             type="rotate"
//             from={from}
//             to={to}
//             dur="40s"
//             repeatCount="indefinite"
//           />
//         </ellipse>
//       ))}
//     </g>
//   </svg>
// )

export default ({ href, title, tech, agency, layers, imageSrc, index }) => {
  // const videoRef = useRef(null)

  // const wrapperRef = useRef(null)
  // const [isActive, setActive] = useState(false)

  return (
    <FocusContext.Consumer>
      {({ focusMethod }) => (
        <div
          // ref={wrapperRef}
          css={css`
            position: relative;
            &:before {
              content: "";
              position: absolute;
              top: -40px;
              bottom: -40px;
              left: -40px;
              right: -40px;
            }
          `}
        >
          {/* <ScrollTrap
            css={css`
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: #034453;
              z-index: 1;
              transition: opacity 0.6s ease;
              opacity: ${isActive ? 1 : 0};
              pointer-events: ${isActive ? "auto" : "none"};
            `}
            onClick={() => setActive(false)}
            enabled={isActive}
          >
            <div
              css={css`
                // z-index: 2;
                opacity: ${isActive ? 1 : 0};
                pointer-events: none;
                transition: opacity 0.6s ease, transform 0.6s ease;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(${isActive ? 1 : 0.9});
                width: 100%;
                height: 100%;
              `}
            >
              <BlurSVG />
            </div>
            <Screen
              css={css`
                min-height: 100vh;
                display: flex;
                justify-content: ${index % 2 == 0 ? "flex-end" : "flex-start"};
              `}
            >
              <div
                css={css`
                  width: 50%;
                  padding-left: 60px;
                `}
              >
                <Body>
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor. Nullam id dolor id nibh ultricies vehicula ut id elit.
                  Nulla vitae elit libero, a pharetra augue. Fusce dapibus,
                  tellus ac cursus commodo, tortor mauris condimentum nibh, ut
                  fermentum massa justo sit amet risus. Aenean lacinia bibendum
                  nulla sed consectetur. Nullam quis risus eget urna mollis
                  ornare vel eu leo.
                </Body>
              </div>
            </Screen>
          </ScrollTrap> */}

          <div
            css={css`
              position: relative;
              z-index: 3;
            `}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={href}
              css={css`
                display: block;
              `}
              title={`Launch ${title} in new window`}
              data-focus-method={focusMethod}
            >
              <Tilt
                glareEnable
                glareMaxOpacity={0.1}
                glarePosition={"all"}
                tiltReverse
                tiltMaxAngleX={2}
                tiltMaxAngleY={2}
                scale={1.02}
                transitionSpeed={1700}
                css={css`
                  transform-style: preserve-3d;
                `}
              >
                {/* <div
                  css={[
                    css`
                      &::before,
                      &::after {
                        content: "";
                        position: absolute;
                        width: ${HOVER_BORDER_SIZE}px;
                        height: ${HOVER_BORDER_SIZE}px;
                        z-index: 1;
                        backface-visibility: none;
                        opacity: 0;
                      }
                      &::before {
                        top: 0;
                        left: 0;
                        background-image: linear-gradient(
                            to left,
                            #f2b705 ${HOVER_BORDER_SIZE}px,
                            transparent ${HOVER_BORDER_SIZE}px
                          ),
                          linear-gradient(
                            #f2b705 ${HOVER_BORDER_SIZE}px,
                            transparent ${HOVER_BORDER_SIZE}px
                          );
                      }
                      &::after {
                        bottom: 0;
                        right: 0;
                        background-image: linear-gradient(
                            to right,
                            #f2b705 ${HOVER_BORDER_SIZE}px,
                            transparent ${HOVER_BORDER_SIZE}px
                          ),
                          linear-gradient(
                            to top,
                            #f2b705 ${HOVER_BORDER_SIZE}px,
                            transparent ${HOVER_BORDER_SIZE}px
                          );
                      }
                      a:focus[data-focus-method="key"] &::before,
                      a:focus[data-focus-method="key"] &::after,
                      a:hover &::before,
                      a:hover &::after {
                        width: 100%;
                        height: 100%;
                      }

                      a:focus[data-focus-method="key"] &::before,
                      a:hover &::before {
                        opacity: 1;
                        transition: width ${duration(0)} ${delay(0)} linear,
                          height ${duration(1)} ${delay(1)} linear;
                      }

                      a:focus[data-focus-method="key"] &::after,
                      a:hover &::after {
                        opacity: 1;
                        transition: opacity 0s linear ${delay(2)},
                          width ${duration(2)} ${delay(2)} linear,
                          height ${duration(3)} ${delay(3)} linear;
                      }
                    `,
                    isActive &&
                      css`
                        &::before,
                        &::after {
                          opacity: 1;
                          width: 100%;
                          height: 100%;
                        }
                      `,
                  ]}
                  onTransitionEnd={({ propertyName, pseudoElement }) => {
                    if (
                      propertyName == "height" &&
                      pseudoElement == "::after"
                    ) {
                      if (wrapperRef)
                        wrapperRef.current.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                          inline: "center",
                        })
                      setTimeout(() => setActive(true), 200)
                    }
                  }}
                /> */}
                <div
                  css={css`
                    max-width: 100%;
                    width: 100%;
                    position: relative;
                    &:before {
                      content: "";
                      position: absolute;
                      top: 0;
                      left: 0;
                      bottom: 0;
                      right: 0;
                      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.7);
                      transform: scaleX(0.98) translateZ(-60px);
                      transition: opacity 0.7s ease;
                      opacity: 0;
                    }

                    a:hover &:before {
                      opacity: 1;
                    }
                  `}
                >
                  <div
                    css={css`
                      position: absolute;
                      top: 0;
                      left: 0;
                      bottom: 0;
                      right: 0;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      z-index: 1;
                      transform: scale(0.9);
                      transform-style: preserve-3d;
                      opacity: 0;
                      transition: opacity 0.4s ease, transform 0.4s ease;
                      a:hover & {
                        opacity: 1;
                        transform: translateZ(60px);
                      }
                    `}
                  >
                    <div
                      css={css`
                        background-color: #f2b705;
                        color: #034453;
                        // padding: 0px 40px;
                        width: 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        line-height: 50px;
                        font-size: 24px;
                        font-weight: 700;
                        border-radius: 50px;
                        position: relative;
                        // z-index: 1;
                        &:before {
                          content: "";
                          position: absolute;
                          border-radius: inherit;
                          top: 0;
                          left: 0;
                          right: 0;
                          z-index: -1;
                          bottom: 0;
                          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
                          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
                          transform: scaleX(0.88) translateZ(-60px);
                        }
                      `}
                    >
                      <svg
                        css={css`
                          width: 24px;
                          height: 24px;
                          display: inline-block;
                          vertical-align: middle;
                          // transform: translateZ(60px);
                        `}
                      >
                        <use xlinkHref={`#${externalIcon.id}`} />
                      </svg>
                    </div>
                  </div>
                  <Image alt={title} imageSrc={imageSrc} />
                  {layers &&
                    layers.map((imageSrc, i) => (
                      <div
                        css={css`
                          position: absolute;
                          top: 0;
                          width: 100%;
                          transform: translateZ(${20 * (i + 1)}px);
                          // z-index: 1;
                        `}
                      >
                        <Image alt={title} imageSrc={imageSrc} />
                      </div>
                    ))}
                </div>
              </Tilt>
              <SubHeading
                css={css`
                  transition: color 0.4s ease;
                  a:hover & {
                    color: #f2b705;
                  }
                `}
              >
                {title}
              </SubHeading>
            </a>
            {tech && <Body>{tech}</Body>}
            {agency && (
              <Body>
                {(agency.href && (
                  <ExternalLink
                    href={agency.href}
                    title={`Visit ${agency.title} in new window`}
                  >
                    Agency: {agency.title}
                  </ExternalLink>
                )) ||
                  `Agency: ${agency.title}`}
              </Body>
            )}
          </div>
        </div>
      )}
    </FocusContext.Consumer>
  )
}
