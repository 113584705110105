import React from "react"
import externalIcon from "../icons/external.svg"
import { Context as FocusContext } from "../components/FocusDisplayHandler"
import { css } from "@emotion/core"
import TextLink from "./TextLink"

export default ({ children, ...props }) => (
  <FocusContext.Consumer>
    {({ focusMethod }) => (
      <TextLink {...props} target="_blank" rel="noopener noreferrer">
        {children}
        <svg
          width="24"
          height="24"
          css={css`
            position: absolute;
            left: 100%;
            margin-left: 8px;
            opacity: 0;
            pointer-events: none;
            transition: transform 0.16s ease-out, opacity 0.05s ease-out;
            transform: scale(0.9);
            transform-origin: center center;
            a[data-focus-method="key"]:focus &,
            a:hover & {
              opacity: 1;
              transform: none;
            }
          `}
        >
          <use xlinkHref={`#${externalIcon.id}`} />
        </svg>
      </TextLink>
    )}
  </FocusContext.Consumer>
)
