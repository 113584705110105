import React from "react"
import { Context as FocusContext } from "../components/FocusDisplayHandler"
import { css } from "@emotion/core"

export default ({ children, ...props }) => (
  <FocusContext.Consumer>
    {({ focusMethod }) => (
      <a
        data-focus-method={focusMethod}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
        css={css`
          display: inline-flex;
          align-items: center;
          position: relative;
          transition: color 0.2s ease-out;
          &:before {
            content: "";
            position: absolute;
            top: -8px;
            left: -8px;
            bottom: -8px;
            right: -8px;
          }
          &:after {
            content: "";
            position: absolute;
            bottom: -4px;
            left: 0;
            right: 0;
            border-bottom-width: 3px;
            transform: translateY(4px) scaleX(0.99);
            transition: transform 0.2s ease-out, opacity 0.15s ease-out;
            opacity: 0;
          }
          &:hover {
            color: #f2b705;
            &:after {
              transform: none;
              opacity: 1;
            }
          }
          &[data-focus-method="key"]:focus {
            outline: 3px solid #f2b705;
            outline-offset: 8px;
          }
        `}
      >
        {children}
      </a>
    )}
  </FocusContext.Consumer>
)
