import React, { useState, useEffect } from "react"

export const Context = React.createContext({ focusMethod: false })

export default ({ children }) => {
  const [focusMethod, setFocusMethod] = useState(false)

  useEffect(() => {
    function handleWindowBlur() {
      setFocusMethod(false)
    }
    window.addEventListener("blur", handleWindowBlur)
    return () => {
      window.removeEventListener("blur", handleWindowBlur)
    }
  })

  useEffect(() => {
    function handleKeyDown() {
      setFocusMethod("key")
    }
    document.addEventListener("keydown", handleKeyDown, true)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  })

  useEffect(() => {
    function handleMouseDown() {
      if (focusMethod === "touch") {
        return
      }
      setFocusMethod("mouse")
    }
    document.addEventListener("mousedown", handleMouseDown, true)
    return () => {
      document.removeEventListener("mousedown", handleMouseDown)
    }
  })

  useEffect(() => {
    function handleTouchStart() {
      setFocusMethod("touch")
    }
    document.addEventListener("touchstart", handleTouchStart, true)
    return () => {
      document.removeEventListener("touchstart", handleTouchStart)
    }
  })

  return <Context.Provider value={{ focusMethod }}>{children}</Context.Provider>
}
