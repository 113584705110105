import styled from "@emotion/styled"
import mq from "../lib/mq"

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => props.justify || "flex-start"};
  margin-left: -64px;
  margin-top: -64px;
  padding-top: 64px;

  ${mq({
    marginLeft: [-24, -32, -64],
    marginTop: [-32, -64, -64],
    paddingTop: [24, 32, 64],
  })}

  & > * {
    ${mq({
      width: ["100%", `50%`],
    })}
  }
`

export const GridItem = styled.div`
  ${mq({
    paddingTop: [32, 64, 64],
    paddingLeft: [24, 32, 64],
  })}
`
