import styled from "@emotion/styled"
import mq from "../lib/mq"

export default styled.h2`
  color: #f2b705;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.4;

  ${mq({
    fontSize: [28, 40, 48],
  })}
`
